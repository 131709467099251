@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Poppins", sans-serif;
  }

  .layout {
    max-width: 88.75rem;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  /*  Hero Section */
  .banner-hero {
    background-image: url("/public/images/bgHero.png");
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    z-index: 10;
  }

  @media (min-width: 768px) {
    .banner-hero {
      height: 500px;
    }
  }

  .gradient {
    background: #33bacf84;
    position: absolute;
    filter: blur(190px);
  }

  .gradient-01 {
    width: 150px;
    height: 438px;
    top: 20%;
    left: 0px;
  }

  .gradient-02 {
    width: 300px;
    height: 438px;
    top: 55%;
    right: 0px;
  }

  .gradient-03 {
    width: 300px;
    height: 438px;
    bottom: 0px;
  }
}
